import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AppConfigService } from '../../config/app-config.service';
import { IReason } from '../../models/reason';

@Injectable({
  providedIn: 'root',
})
export class ReasonService {
  protected apiServer = AppConfigService.settings.url_server;

  constructor(private http: HttpClient) {}

  getAll(): Observable<IReason[]> {
    return this.http.get<IReason[]>(
      this.apiServer + '/backoffice/reason', {});
  }

  create(body:any): Observable<{message:string, reasons:IReason[]}> {
    return this.http.post<{message:string, reasons:IReason[]}>(
      this.apiServer + '/backoffice/reason', body
    );
  }

  destroy(id:any): Observable<{message:string, reasons:IReason[]}>{
    return this.http.delete<{message:string, reasons:IReason[]}>(
      this.apiServer + '/backoffice/reason/'+id
    );
  }

}
