import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { StoreService } from '../services/store.service';
import { environment as env } from 'src/environments/environment';
import { AuthService } from '../services/http/auth.service';
import { AppConfigService } from '../config/app-config.service';
import { isAdmin } from 'src/app/shared/functions/helper';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard implements CanActivate {
  protected apiServer = AppConfigService.settings.url_server;

  constructor(
    private router: Router,
    private storeService: StoreService,
    private authService: AuthService
  ) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    const user: User = this.storeService.getItem('user');
    if (user) {
      if (user.registroCompleto || isAdmin(user)) {
        return true;
      } else {
        this.router.navigate(['/user/register']);
        return false;
      }
    }

    window.location.href =
      this.apiServer + '/auth/redirect?url=' + window.location.href;
    //window.location.href.replace('#', '%23');
    return false;
  }
}
